.loaderWrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &.fullHeight {
    height: 100vh;
  }

  &.smallHeight {
    height: 5vh;
  }

  &.miniHeight {
    width: 40px;
    height: 20px;
  }
}

.customLoader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  &__bar {
    position: absolute;
    top: 33px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--primary-color);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 4px;
      animation: lds-ellipsis1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 4px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }
}

.customLoader.miniHeight {
  margin-top: 10px;

  .customLoader__bar {
    width: 6px;
    height: 6px;

    &:nth-child(2) {
      animation: lds-ellipsis4 0.6s infinite;
    }

    &:nth-child(3) {
      left: 18px;
      animation: lds-ellipsis4 0.6s infinite;
    }

    &:nth-child(4) {
      left: 30px;
    }
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes lds-ellipsis4 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}
