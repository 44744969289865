.linksWrapper {
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 200px;
  a {
    color: var(--gray);
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    transition: 0.1s;
    -webkit-transition: 0.1s;
    &:hover {
      text-decoration: underline;
    }
  }
}
